import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Images } from "../../../../helpers/Images";
import { categoriesData, Q_Follow } from "../../../../helpers/Arrays";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const LineFollowerTemplate = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Typography
              variant={"h1"}
              sx={{
                textAlign: "start",
                fontSize: { xs: "40px", sm: "55px" },
                fontWeight: "500",
                color: "primary.darker",
              }}
            >
              Line Follower
            </Typography>
            <Box
              sx={{
                height: "3px",
                maxWidth: { xs: "100px", md: "150px" },
                width: "100%",
                backgroundColor: "primary.main",
                margin: "10px 0 0 0",
              }}
            />
            <Typography
              sx={{
                textAlign: "left",
                color: "primary.darker",
                maxWidth: "750px",
                margin: "25px auto 10px auto",
                fontSize: "17px",
              }}
            >
              <span style={{ fontWeight: "700", color: "#830d0b" }}>Age:</span>{" "}
              9-24 <br />
              <span style={{ fontWeight: "700", color: "#830d0b" }}>
                Team size:
              </span>{" "}
              3-5 people guided by a coach <br />
              <span style={{ fontWeight: "700", color: "#830d0b" }}>
                Hardware:
              </span>{" "}
              <br />
              Line Follower Open ( using any type of robot ), <br />
              Line Follower LEGO ( using LEGO® robot only ), <br />
              Line Follower Enhanced ( using any type of robot )
              <br />
              {/* <span style={{ fontWeight: "700", color: "#830d0b" }}>
                Software:
              </span>{" "}
              Free choice <br /> */}
              <span style={{ fontWeight: "700", color: "#830d0b" }}>
                Maximum size:
              </span>{" "}
              Max. 30 x 30 cm, with max. 3kg
              {/* <br />
              <span style={{ fontWeight: "700", color: "#830d0b" }}>
                Characteristics:
              </span>{" "}
              Teams design 2 robots that compete with robots of another team.{" "} */}
              <br />
              <br />
              <Box
                sx={{
                  height: "3px",
                  width: "100%",
                  backgroundColor: "primary.main",
                }}
              />
              <br />
              The objective of this category is that each robot completes the
              line tracking accurately from start point to the end point in the
              shortest period of time.
            </Typography>
            <br />
            <Box
              sx={{
                height: "3px",
                width: "100%",
                backgroundColor: "primary.main",
              }}
            />
            <br />
            <Typography
              sx={{
                // textAlign: "center",
                maxWidth: "750px",
                fontSize: "22px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1eSLEe4IAb4yP1RDP2SLzY0GQ8AAJsqZl"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "17px",
                      margin: "5px 0",
                      fontWeight: "600",
                    }}
                  >
                    Download Detailed Rules ( PDF )
                  </Button>
                </a>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <a
                  download
                  href={
                    "https://drive.google.com/uc?export=download&id=1on-ApfFwUIfD03yhsmhc-Ue_ZJyScqPP"
                  }
                >
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      fontSize: "17px",
                      margin: "5px 0",
                      fontWeight: "600",
                    }}
                  >
                    Download Followline enhanced Rules ( PDF )
                  </Button>
                </a>
              </Box>
            </Typography>
            <Box
            // sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Button
                variant="contained"
                color="primary"
                sx={{
                  fontSize: "17px",
                  margin: "10px 0",
                  fontWeight: "600",
                  backgroundColor: "primary.main",
                }}
                onClick={() => navigate("/firststage")}
              >
                Register Now
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <img
              alt="follow1"
              src={Images.follow3}
              style={{ width: "100%", borderRadius: "10px" }}
            />
            <img
              alt="follow2"
              src={Images.follow2}
              style={{ width: "100%", borderRadius: "10px" }}
            />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          width: "100%",
          padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
          maxWidth: "1500px",
          margin: "auto",
        }}
      >
        <Typography
          variant={"h1"}
          sx={{
            textAlign: "start",
            fontSize: { xs: "40px" },
            fontWeight: "500",
            color: "primary.darker",
          }}
        >
          Questions & Answers
        </Typography>
        <Box
          sx={{
            height: "3px",
            maxWidth: { xs: "100px", md: "200px" },
            width: "100%",
            backgroundColor: "primary.main",
            margin: "10px 0 0 0",
          }}
        />
        <Box id={"questions"} sx={{ marginTop: "50px" }}>
          {Q_Follow.map((item) => (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{item.question}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>{item.answer}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          backgroundColor: "primary.main",
        }}
      >
        <Box
          sx={{
            width: "100%",
            padding: { xs: "50px 20px", sm: "50px 60px", lg: "50px 120px" },
            maxWidth: "1500px",
            margin: "auto",
          }}
        >
          <Typography
            variant={"h2"}
            sx={{
              textAlign: "center",
              fontSize: { xs: "40px" },
              fontWeight: "500",
              color: "secondary.main",
            }}
          >
            Our All Categories
          </Typography>
          <Box
            sx={{
              height: "3px",
              maxWidth: { xs: "100px", md: "150px" },
              width: "100%",
              backgroundColor: "secondary.main",
              margin: "10px auto 0 auto",
            }}
          />
          <Grid
            container
            spacing={2}
            sx={{ marginTop: "50px", justifyContent: "center" }}
          >
            {categoriesData
              ?.filter((category) => category.title !== "Line Follower")
              ?.map((item) => (
                <Grid item xs={12} sm={6} md={3}>
                  <Box
                    sx={{
                      backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${item.image})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-end",
                      width: "100%",
                      minHeight: { xs: "200px", sm: "250px" },
                      borderRadius: "10px",
                    }}
                  >
                    <Typography
                      variant={"h3"}
                      sx={{
                        textAlign: "center",
                        fontSize: "35px",
                        fontWeight: "500",
                        color: "secondary.main",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Box
                      sx={{
                        height: "3px",
                        maxWidth: "90px",
                        width: "100%",
                        backgroundColor: "secondary.main",
                        margin: "5px auto 5px auto",
                      }}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        sx={{
                          fontSize: "13px",
                          fontWeight: "600",
                          color: "primary.main",
                          width: "fit-content",
                          margin: { xs: "5px 0 20px 0", sm: "10px 0 40px 0" },
                        }}
                        onClick={() => navigate(item.link)}
                      >
                        view
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              ))}
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default LineFollowerTemplate;
