import { Box, Button, Typography } from "@mui/material";
import React from "react";
import "./CountDown.css";
import Countdown from "react-countdown";
import Count from "../../../molecules/Count/Count";
import { Images } from "../../../../helpers/Images";
import { useNavigate } from "react-router-dom";

const CountDown = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        width: "100%",
        padding: {
          xs: "50px 20px",
          sm: "50px 60px",
          lg: "50px 120px",
          xl: "150px 120px",
        },
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${Images.gold})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        minHeight: "500px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Typography
        variant={"h1"}
        sx={{
          textAlign: "center",
          fontSize: { xs: "50px", sm: "55px" },
          fontWeight: "500",
          color: "secondary.main",
        }}
      >
        Welcome to International Robot Challenge 2024
      </Typography>
      <Typography
        variant={"h2"}
        sx={{
          textAlign: "center",
          fontSize: { xs: "20px", sm: "30px" },
          fontWeight: "500",
          marginTop: "5px",
          color: "secondary.main",
        }}
      >
        ~ 28 - 09 - 2024 ~
      </Typography>
      <Countdown
        date={"2024-09-28T24:00:00"}
        intervalDelay={0}
        precision={3}
        renderer={(props) => (
          <Count
            days={props.days}
            hours={props.hours}
            minutes={props.minutes}
            seconds={props.seconds}
          />
        )}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
          marginTop: "20px",
        }}
      >
        {/* <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Button
            variant="contained"
            color="secondary"
            sx={{
              fontSize: "17px",
              marginTop: "25px",
              fontWeight: "600",
              backgroundColor: "secondary.main",
              color: "primary.main",
            }}
            onClick={() => navigate("/firststage")}
          >
            Register Now
          </Button>
        </Box> */}
        {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
          <a
            download
            href={
              "https://drive.google.com/uc?export=download&id=1_DXgoDNJx6axef2J-hWuHMuaigODEKk-"
            }
          >
            <Button
              variant="contained"
              color="secondary"
              sx={{
                fontSize: "17px",
                marginTop: "25px",
                fontWeight: "600",
                backgroundColor: "secondary.main",
                color: "primary.main",
                width: { xs: "300px", sm: "300px" },
              }}
            >
              International Robot Challenge Schedule
            </Button>
          </a>
        </Box> */}
      </Box>
    </Box>
  );
};

export default CountDown;

// RobotChallenge is one of the biggest competitions for self-made, autonomous and mobile robots worldw
